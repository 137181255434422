<template>
  <el-cascader
    v-model="areaArr"
    v-bind="$attrs"
    :props="{children: 'subarea', value: 'areacode', label: 'areaname'}"
    :options="areaData"
    @change="onChange">
  </el-cascader>
</template>

<script>
export default {
  name: 'CustomArea',
  props: {
    value: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    value (value) {
      this.areaArr = value
    }
  },
  data () {
    return {
      areaArr: [],
      areaData: []
    }
  },
  created () {
    this.loadCity()
  },
  methods: {
    loadCity () {
      this.$axios.get(this.$apis.common.selectCity).then(res => {
        this.areaData = res
      })
    },
    onChange (data) {
      this.$emit('change', data)
      this.$emit('input', data)
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
