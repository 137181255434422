import Layout from '@/layout/Index.vue'

export default [{
  path: '/device',
  meta: { title: '设备管理' },
  component: Layout,
  redirect: '/device/list',
  children: [
    {
      path: 'list',
      name: 'deviceList',
      meta: { title: '设备管理' },
      component: () => import('@/views/device/List'),
      children: [
        {
          path: 'list/:deptId/:parentDeptId',
          name: 'deviceListPage',
          meta: { title: '设备管理', activeMenu: '/device/list' },
          component: () => import('@/views/device/DeviceList')

        },
        {
          path: 'detail/:deptId/:parentDeptId',
          name: 'deviceDetailPage',
          meta: { title: '设备管理', activeMenu: '/device/list' },
          component: () => import('@/views/device/DeviceDetail')
        },
        {
          path: 'childdetail/:deptId/:deviceType',
          name: 'deviceChildDetailPage',
          meta: { title: '设备管理', activeMenu: '/device/list' },
          component: () => import('@/views/device/DeviceChildDetail')
        }
      ]
    },
    {
      path: 'add',
      name: 'deviceAdd',
      meta: {
        title: '新增',
        activeMenu: '/device/list'
      },
      component: () => import('@/views/device/Add')
    },
    {
      path: 'edit:id',
      name: 'deviceEdit',
      meta: {
        title: '编辑',
        activeMenu: '/device/list'
      },
      component: () => import('@/views/device/Add')
    },
    {
      path: 'detail:id',
      name: 'deviceDetail',
      meta: {
        title: '详情',
        activeMenu: '/device/list'
      },
      component: () => import('@/views/device/Detail')
    }, {
      path: 'allEdit',
      name: 'deviceAllEdit',
      meta: {
        title: '批量编辑',
        activeMenu: '/device/list'
      },
      component: () => import('@/views/device/Add')
    }, {
      path: 'history',
      meta: {
        title: '历史数据',
        activeMenu: '/device/list'
      },
      component: () => import('@/views/device/History')
    }
  ]
}]
