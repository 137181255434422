import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import apis from '@/apis/index'
import axios from '@/utils/request'
import config from '../config'
import dayjs from 'dayjs'
import './assets/theme/index.css'
import './assets/css/reset.scss'
import './assets/css/common.scss'
import CustomAction from './components/CustomActions.vue'
import VueAnimateNumber from 'vue-animate-number'
import CustomArea from './components/CustomArea.vue'
import CustomLicenseInfo from './components/CustomLicenseInfo.vue'
import Pagination from './components/pagination/Index.vue'
import globalMethods from '@/utils/globalMethods' // 定义全局methods
Vue.config.productionTip = false
Vue.prototype.$apis = apis
Vue.prototype.$axios = axios
Vue.prototype.$config = config
Vue.prototype.$dayjs = dayjs
Vue.prototype.$event = new Vue()

Vue.component(CustomAction.name, CustomAction)
Vue.component(CustomArea.name, CustomArea)
Vue.component(CustomLicenseInfo.name, CustomLicenseInfo)
Vue.component(Pagination.name, Pagination)
Vue.use(globalMethods)
Vue.use(VueAnimateNumber)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
