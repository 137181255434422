export default {

  /** 菜单列表 */
  selectSysMenuByList: '/service/sysMenu/selectSysMenuByList',
  /** 我的菜单雷彪 */
  selectSysMenuMyList: '/service/sysMenu/selectSysMenuMyList',
  /** 菜单列表分页 */
  selectSysMenuByPage: '/service/sysMenu/selectSysMenuByPage',
  /** 菜单新增 */
  insertSysMenu: '/service/sysMenu/insertSysMenu',
  /** 菜单修改 */
  updateSysMenu: '/service/sysMenu/updateSysMenu',
  /** 菜单激活禁用 */
  disableSysMenu: '/service/sysMenu/disableSysMenu',
  /** 菜单详情 */
  selectSysMenuByMenuId: '/service/sysMenu/selectSysMenuByMenuId',

  /** 新增角色 */
  insertSysRole: '/service/sysRole/insertSysRole',
  /** 修改角色 */
  updateSysRole: '/service/sysRole/updateSysRole',
  /** 删除角色 */
  deleteSysRole: '/service/sysRole/deleteSysRole',
  /** 角色详情 */
  selectSysRoleByRoleId: '/service/sysRole/selectSysRoleByRoleId',
  /**  查询菜单候选下拉 */
  selectSysMenuById: '/service/sysRole/selectSysMenuById',
  /** 查询角色列表 */
  selectSysRoleByList: '/service/sysRole/selectSysRoleByList',

  /** 新增数据字典 */
  insertDic: '/service/dic/insertDic',
  /** 修改数据字典 */
  updateDic: '/service/dic/updateDic',
  /** 查询数据字典详情 */
  selectDicByDicId: '/service/dic/selectDicByDicId',
  /** 分页数据字典列表 */
  selectDicByPage: '/service/dic/selectDicByPage',
  /** 根据类型查询数据字典 */
  selectDicByList: '/service/dic/selectDicByList',
  /** 同步数据字典到redis  */
  synchro: '/service/dic/synchro',

  /**  禁用/启用用户 */
  deleteSysUser: '/service/sysUser/deleteSysUser',
  /** 分页查询用户列表 */
  selectSysUserByPage: '/service/sysUser/selectSysUserByPage',
  /** 重置用户密码  */
  resetPassword: '/service/sysUser/resetPassword',
  /** 修改当前登录用户密码 */
  updatePassword: '/service/sysUser/updatePassword',
  /** 新增用户 */
  insertSysUser: '/service/sysUser/insertSysUser',
  /** 修改用户 */
  updateSysUser: '/service/sysUser/updateSysUser',
  /** 修改用户 */
  selectSysUserByUserId: '/service/sysUser/selectSysUserByUserId',

  /** 新增部门组织 */
  insertSysDept: '/service/sysDept/insertSysDept',
  /** 修改部门组织 */
  updateSysDept: '/service/sysDept/updateSysDept',
  /**  删除部门组织 */
  deleteSysDept: '/service/sysDept/deleteSysDept',
  /** 查询部门组织列表 */
  selectSysDeptByList: '/service/sysDept/selectSysDeptByList',
  /** 查询部门组织详情 */
  selectSysDeptByDeptId: '/service/sysDept/selectSysDeptByDeptId',
  /** 查询部门懒加载列表 */
  selectSysDeptTree: '/service/sysDept/selectSysDeptTree',
  /** 查询部门懒加载列表-智慧空开 */
  selectSysDeptTreeWithSmartDevice: '/service/sysDept/selectSysDeptTreeWithSmartDevice',
  /** 查询用户操作日志 */
  selectOperatorLogByPage: '/service/selectOperatorLogByPage'
}
