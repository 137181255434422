export default {
  /** 查询设备列表 */
  selectDeviceByList: '/service/device/selectDeviceByList',
  /**  查询设备详情 */
  selectDeviceByDeviceNum: '/service/device/selectDeviceByDeviceNum',
  /**  查询单个设备详情 */
  selectDeviceNum: '/service/device/selectDeviceNum',
  /**  删除设备 */
  deleteDevice: '/service/device/deleteDevice',
  /** 修改设备  */
  updateDevice: '/service/device/updateDevice',
  /**  新增设备 */
  insertDevice: '/service/device/insertDevice',
  /**  批量修改设备 */
  updateAllDevice: '/service/device/updateAllDevice',
  /**  设备合闸 */
  openDevice: '/service/device/openDevice',
  /** 设备分闸 */
  closeDevice: '/service/device/closeDevice',

  /** 设备历史记录 */
  selectDeviceDataByPage: '/service/deviceData/selectDeviceDataByPage',
  /** 设备历史导出 */
  exportDeviceData: '/service/deviceData/exportDeviceData',

  /** 历史告警消息列表 */
  selectMsgByPage: '/service/msg/selectMsgByPage',
  /** 告警确认 */
  confirmMsg: '/service/msg/confirmMsg',
  /** 告警导出 */
  /** 历史告警消息导出 */
  exportMsg: '/service/msg/exportMsg',
  /** 声光告警 */
  selectMsgByList: '/service/msg/selectMsgByList',

  selectMsgByNotify: '/service/msg/selectMsgByNotify',
  /** 告警消息配置查询 */
  selectMsgConf: '/service/msg/selectMsgConf',
  /** 告警消息配置 */
  msgConf: '/service/msg/msgConf'
}
