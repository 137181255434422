import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Index.vue'
import system from './system'
import device from './device'
import alarm from './alarm'
import store from '../store'
import power from './power'
import smartDevice from './smartDevice'

Vue.use(VueRouter)

const constantRoutes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: (data) => {
      if (store.state.token) {
        return '/index'
      } else {
        return '/login'
      }
    },
    children: [{
      path: 'index',
      component: () => import('@/views/welcome/Index.vue')
    }, {
      path: 'other1',
      component: () => import('@/views/other/Other.vue')
    }]
  },
  {
    path: '/login',
    component: () => import('@/views/login/Index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  }

]

const asyncRoutes = [
  ...system, // 系统管理
  ...device, // 设备管理
  ...alarm, // 告警管理
  ...smartDevice,
  ...power,
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  routes: constantRoutes.concat(asyncRoutes)
})

export default router
