<template>
  <div>
    <div class="global-column-title">
      <span>证照信息</span>
      <el-button size="mini" type="primary" @click="dialogVisible = true">新增</el-button>
    </div>
    <el-table border :data="tableData">
      <el-table-column prop="licenseNumber" label="证照号码"></el-table-column>
      <el-table-column prop="expiryDate" label="有效截止日期"></el-table-column>
      <el-table-column prop="licenseType" label="证照类型"></el-table-column>
      <el-table-column prop="certificationMark" label="认证标识">
        <template v-slot="{ row }">{{row.certificationMark === '1' ? '是' : '否'}}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button class="del-btn" type="text" @click="deleteLicense(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="上传证件" :visible.sync="dialogVisible" width="500px">
      <el-form :model="form" :rules="rules" ref="form" label-position="right" label-width="120px">
        <el-form-item prop="licenseNumber" label="证照号码">
          <el-input v-model="form.licenseNumber" placeholder="请输入证照号码"/>
        </el-form-item>
        <el-form-item prop="expiryDate" label="有效截止日期">
          <el-date-picker v-model="form.expiryDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item prop="licenseType" label="证照类型">
          <el-input v-model="form.licenseType" placeholder="请输入证照类型"/>
        </el-form-item>
        <el-form-item prop="certificationMark" label="认证标识">
          <el-radio-group v-model="form.certificationMark">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="" label="附件">
          <el-upload
            :action="$apis.common.uploadUrl"
            :on-success="uploadSuccess"
            multiple>
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'CustomLicenseInfo',
  props: {
    objectId: String
  },
  data () {
    return {
      dialogVisible: false,
      tableData: [],
      form: {
        objectId: '', // 关联学校或单位或供应商
        licenseNumber: '', // 证照号码
        expiryDate: '', // 有效截止日期
        licenseType: '', // 证照类型
        certificationMark: '', // 认证标识
        fileList: [] // 附件列表
      },
      rules: {
        licenseNumber: [{ required: true, message: '请输入证件号码' }],
        expiryDate: [{ required: true, message: '请选择有效截止日期' }]
      }
    }
  },
  computed: {
    fileList () {
      return this.form.fileList.map(item => {
        return { name: item.fileName, url: item.filePrefix + item.fileUrl }
      })
    }
  },
  created () {
    console.log('id::::', this.objectId)
  },
  watch: {
    objectId: {
      immediate: true,
      handler (value) {
        if (value) {
          this.form.objectId = value
          this.loadData()
        }
      }
    }
  },
  methods: {
    loadData () {
      console.log('执行')
      this.$axios.get(this.$apis.schoolCompany.selectCertificatesByList, {
        params: { objectId: this.objectId }
      }).then(res => {
        this.tableData = res.list
      })
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.form.fileList.push(res.data)
      }
    },
    submit () {
      this.$refs.form.validate().then(() => {
        this.$axios.post(this.$apis.schoolCompany.insertCertificates, this.form).then(() => {
          this.dialogVisible = false
          this.loadData()
        })
      })
    },
    deleteLicense (id) {
      this.$confirm('确认删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.schoolCompany.deleteDataCertificates, {
          id
        }).then(() => {
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.el-date-editor {
  width: 100%;
}
</style>
