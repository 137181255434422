import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router/index'

const service = axios.create({
  baseURL: '',
  timeout: 10000
})

service.interceptors.request.use(config => {
  if (store.state.token) {
    config.headers.token = store.state.token
  }
  if (config.method === 'get') {
    for (const key in config.params) {
      if (config.params[key] === '') {
        config.params[key] = null
      }
    }
  }
  return config
}, error => {
  console.log(error)
  return Promise.reject(error)
})

service.interceptors.response.use(response => {
  const res = response.data

  if (res.code !== 200) {
    Message({
      message: res.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    })

    if (res.code === 100000) {
      router.replace('/login')
    }
    return Promise.reject(new Error(res.message || 'Error'))
  } else {
    if (response.config.method === 'post' && response.config.url !== '/hntscxt/login') {
      Message({ type: 'success', message: res.message })
    }
    return res.data
  }
}, error => {
  console.log('err' + error) // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
})

export default service
