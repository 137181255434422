<template>
  <el-menu
    router
    unique-opened
    :default-active="activeMenu"
    :collapse="isCollapse"
    background-color="#232d2c"
    text-color="#bfcbd9"
    active-text-color="#409EFF"
  >
    <template v-for="item of menus">
      <el-submenu v-if="item.childNode && item.childNode.length" :key="item.menuId" :index="item.url">
        <template slot="title">
          <i :class="item.iconPath"></i>
          <span>{{item.menuName}}</span>
        </template>
        <el-menu-item v-for="child of item.childNode" :index="child.url" :key="child.menuId">
          <i class="child-icon" :class="child.iconPath"></i>
          <span>{{child.menuName}}</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :index="item.url" :key="item.menuId">
        <div class="menu">
          <i v-if="item.iconPath" :class="item.iconPath"></i>
          <span slot="title">{{item.menuName}}</span>
        </div>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
export default {
  name: 'SideBar',
  data () {
    return {
      isCollapse: false
    }
  },
  computed: {
    activeMenu () {
      const { meta, path } = this.$route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    menus () {
      return this.$store.state.menus
    }
  },
  created () {
    this.$store.dispatch('loadUserMenus')
  },
  methods: {
  }
}
</script>

<style scoped lang='scss'>
.el-menu {
  width: 100px;
  border-right: 0;

  ::v-deep .el-menu-item {
    height: 80px;
    background-color: #232d2c !important;
    padding: 0 !important;

    .menu {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      i {
        font-size: 25px;
      }

      span {
        line-height: 14px;
        margin-top: 10px;
      }
    }

    &.is-active {
      background: rgb(0, 113, 101, .5) !important;
      color: #ffffff !important;
    }
  }

  ::v-deep .el-menu-item:hover {
    background-color: $color-primary !important;
  }

  ::v-deep .el-submenu__title i {
    color: #bfcbd9;
  }

  ::v-deep .el-menu--inline {
    background-color: rgb(38, 52, 69) !important;
  }

  .child-icon {
    font-size: 14px;
    margin-right: 5px;
  }
}

.menu {
  // display: flex;
  // flex-direction: column;
}
</style>
