import common from './common'
import system from './system'
import device from './device'
import smartDevice from './smartDevice'
import power from './power'

export default {
  common,
  system,
  device,
  smartDevice,
  power
}
