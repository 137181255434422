import Layout from '@/layout/Index.vue'

export default [
  {
    path: '/system',
    meta: { title: '系统管理' },
    component: Layout,
    children: [
      {
        path: 'menu',
        meta: { title: '菜单管理' },
        component: () => import('@/views/system/MenuManage')
      },
      {
        path: 'menu/add',
        meta: {
          title: '新增菜单',
          activeMenu: '/system/menu'
        },
        component: () => import('@/views/system/MenuAdd')
      },
      {
        path: 'menu/edit',
        meta: {
          title: '编辑菜单',
          activeMenu: '/system/menu'
        },
        component: () => import('@/views/system/MenuAdd')
      },
      {
        path: 'organize',
        meta: { title: '基站管理' },
        component: () => import('@/views/system/OrganizeManage')
      },
      {
        path: 'role',
        meta: { title: '角色管理' },
        component: () => import('@/views/system/RoleManage')
      },
      {
        path: 'role/(add|edit)',
        meta: { title: '角色新增' },
        component: () => import('@/views/system/RoleAdd')
      },
      {
        path: 'dict',
        meta: { title: '字典管理' },
        component: () => import('@/views/system/DictManage')
      },
      {
        path: 'account',
        meta: { title: '账号管理' },
        component: () => import('@/views/system/AccountManage')
      },
      {
        path: 'account/add',
        meta: {
          title: '新增账号',
          activeMenu: '/system/account'
        },
        component: () => import('@/views/system/AccountAdd')
      },
      {
        path: 'account/edit',
        meta: {
          title: '编辑账号',
          activeMenu: '/system/account'
        },
        component: () => import('@/views/system/AccountAdd')
      },
      {
        path: 'devices',
        component: () => import('@/views/system/DevicesManage')
      },
      {
        path: 'log',
        component: () => import('@/views/system/SysLog'),
        meta: {
          title: '系统日志',
          activeMenu: '/system/log'
        }
      }
    ]
  }
]
