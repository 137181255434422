export default {

  /** 验证码 */
  getVerifyCode: '/service/getVerifyCode',
  /** 登录 */
  login: '/service/login',
  /** 省市区 */
  selectCity: '/service/dic/selectCity',
  /** 文件上传 */
  uploadUrl: '/service/fdfs/upload',
  /** 数据字典 */
  selectDicByList: '/service/dic/selectDicByList',
  /** 首页数据统计 */
  selectDeviceCount: '/service/device/selectDeviceCount',
  /** 首页用电量统计 */
  selectDeviceElectricity: '/service/device/selectDeviceElectricity'
}
