import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/utils/request'
import apis from '@/apis/index'
import router from '../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token'),
    user: JSON.parse(sessionStorage.getItem('user') || '{}'),
    menus: [],
    unitList: [],
    permission: {}
  },
  mutations: {
    setToken (state, data) {
      state.token = data
      sessionStorage.setItem('token', data)
    },
    setUser (state, data) {
      state.user = data
      sessionStorage.setItem('user', JSON.stringify(data))
    },
    setUserMenus (state, data) {
      state.menus = data
    },
    setPermission (state, path) {
      state.menus.forEach(item => {
        if (path.includes(item.url)) {
          const permission = {}
          ;(item.button || []).forEach(type => {
            permission[type.butCode] = true
          })
          state.permission = permission
        }
      })
    }
  },
  actions: {
    loadUserMenus ({ commit }) {
      axios.get(apis.system.selectSysMenuMyList).then(res => {
        commit('setUserMenus', res)
        commit('setPermission', router.currentRoute.path)
        sessionStorage.setItem('menus', JSON.stringify(res))
      })
    }
  },
  modules: {
  }
})
