<template>
  <el-container class="main">
    <el-header class="header">
      <div class="left">
        <img src="@/assets/logo.svg" alt="">
        <span class="title">智慧用电平台</span>
      </div>
      <div class="header-right">
        <span>欢迎，</span>
        <i class="user-icon el-icon-user-solid"></i>
        <el-dropdown trigger="click" class="info">
          <span class="el-dropdown-link">
            {{user.loginId}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="editPassword">修改密码</el-dropdown-item>
            <el-dropdown-item @click.native="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container class="main-content">
      <el-aside width="100px">
        <side-bar></side-bar>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <el-dialog title="密码修改" close-on-click-modal  :visible.sync="dialogVisible" width="400px">
      <el-form :model="form" :rules="rules" ref="form" label-width="80px">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model="form.oldPassword" placeholder="请输入旧密码"/>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input type="password" v-model="form.newPassword" placeholder="请输入新密码"/>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input type="password" v-model="form.confirmPassword" placeholder="请再次输入新密码"/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="savePasswrd">确定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import SideBar from './SideBar'
export default {
  name: 'Index',
  components: {
    SideBar
  },
  data () {
    const validatePass = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const password = (rule, value, callback) => {
      const reg = /^[(a-z|A-Z|0-9)]{6,10}$/
      if (value && !reg.test(value)) {
        callback(new Error('请输入6-10英文字母或数字'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      rules: {
        oldPassword: [{ required: true, validator: password, trigger: ['blur', 'change'] }],
        newPassword: [{ required: true, validator: password, trigger: ['blur', 'change'] }],
        confirmPassword: [{ required: true, validator: validatePass, trigger: ['blur', 'change'] }]
      }
    }
  },
  created () {

  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    exit () {
      this.$store.commit('setToken', '')
      this.$router.replace('/login')
      this.$event.$emit('clearMsg')
    },
    editPassword () {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    savePasswrd () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const info = {
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
            userId: this.user.userId
          }
          this.$axios.post(this.$apis.system.updatePassword, info).then(() => {
            this.dialogVisible = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
.main {
  height: 100vh;
}

.header {
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
      width: 60px;
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .info {
      color: #ffffff;
      cursor: pointer;
    }

    .user-icon {
      margin-right: 5px;
    }
  }
}

.el-header {
  // background-color: #207cdc;
  // background-image: linear-gradient(to right, #0e1631, #12245b);
  // background: linear-gradient(270deg, #06CED4 0%, #0E6AD7 100%);
  background-color: $color-primary;
  color: #fff;
  display: flex;
  align-items: center;

  .title {
    font-size: 20px;
  }
}

.main-content {
  height: 0;
  background-color: #E8EAF6;
}

.el-aside {
  background-color: #232d2c;
  overflow-x: hidden;
  animation: right .5s;
  &::-webkit-scrollbar {
    width: 0;
  }
}

@keyframes right {
  0% {
    transform: translate3d(-100%, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.el-main {
  background-color: #E8EAF6;
  position: relative;
  animation: left .5s;
  padding: 15px;
}

@keyframes left {
  0% {
    transform: translate3d(100px, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
</style>
