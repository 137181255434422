import Layout from '@/layout/Index.vue'

export default [{
  path: '/alarm',
  meta: { title: '告警管理' },
  component: Layout,
  redirect: '/alarm/list',
  children: [
    {
      path: 'list',
      meta: { title: '告警消息' },
      component: () => import('@/views/alarm/AlarmList')
    },
    {
      path: 'setting',
      meta: { title: '告警消息配置' },
      component: () => import('@/views/alarm/AlarmSetting')
    }
  ]
}]
