<template>
  <div id="app" @click="playAudio">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  components: {
  },
  data () {
    return {
      num: 0,
      messages: []
    }
  },
  computed: {
    menus () {
      return this.$store.state.menus
    }
  },
  watch: {
    $route (to, from) {
      if (this.menus && this.menus.length) {
        this.$store.commit('setPermission', to.path)
      }
    },
    messages () {
    }
  },
  created () {
    this.$event.$on('loadMsg', this.loadMsg)
    this.$event.$on('clearMsg', this.clearMsg)
    if (this.$store.state.token) {
      this.loadMsg()
    }
  },
  methods: {
    loadMsg () {
      this.num = 0
      this.loadData()
      this.initTimer()
      this.createAudio()
    },
    clearMsg () {
      this.num = 0
      this.messages = []
    },
    loadData () {
      return this.$axios.get(this.$apis.device.selectMsgByNotify).then(res => {
        this.messages = res || []
      })
    },
    createAudio () {
      this._audio = document.createElement('AUDIO')
    },
    playAudio () {
      if (this.num === 0 && this.messages && this.messages.length) {
        this.num++
        this.read()
      }
    },
    read () {
      const data = this.messages.shift()
      if (data) {
        const notify = this.$notify({
          title: data.stationName + '(' + data.deviceNum + ')',
          message: '[' + data.msgTypeName + ']' + data.msg,
          type: 'warning',
          duration: 0,
          onClose: () => {
            // this.read()
          }
        })
        if (data.wavFile) {
          this._audio.src = data.wavFile.filePrefix + data.wavFile.fileUrl
          this._audio.play()
          this._audio.onended = () => {
            notify.close()
            this.read()
          }
        }
      }
    },
    initTimer () {
      setInterval(() => {
        this.loadData().then(this.read)
      }, 60 * 5 * 1000)
    }
  }
}
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}
body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}

body .el-table th.gutter{
  display: table-cell !important;
}

html {
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}
</style>
