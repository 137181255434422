export default {
  install (Vue) {
    // 表格居中
    Vue.prototype.tableCenter = function () {
      return 'text-align: center;'
    }
    // 表头
    Vue.prototype.tableHeaderCenter = function () {
      return `
            text-align: center;
            font-size: 14px;
            font-weight:500;
            color: #333;
            background: #fafcff;
        `
    }
  }
}
