<template>
  <el-card shadow="never" :class="{'no-panel': !panel}">
    <template #header>
      <div class="header">
        <div class="left-header">
          <div class="back" @click="$router.back()" v-if="showBack">
            <i class="el-icon-back"></i>
            <span>返回</span>
          </div>
          <span class="title">{{title || menu.meta.title}}</span>
        </div>
        <div class="right-header">
          <slot name="header"></slot>
        </div>
      </div>
    </template>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  name: 'CustomActions',
  props: {
    panel: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    showBack: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
    menu () {
      return this.$route.matched[this.$route.matched.length - 1]
    }
  },
  methods: {

  }
}
</script>

<style scoped lang='scss'>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding-left: 20px;
  padding-right: 30px;
}

.right-header {
  display: flex;
  align-items: center;
}

.left-header {
  display: flex;
  align-items: center;
  justify-content: center;

  .back {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-left: -5px;
    border-right: 1px solid #ccc;
    padding-right: 10px;
    cursor: pointer;

    i {
      font-size: 14px;
    }

    span {
      font-size: 12px;
      margin-left: 5px;
      line-height: 12px;
    }
  }

  .title {
    font-size: 16px;
    line-height: 16px;
  }
}

.el-card {
  &.no-panel {
    ::v-deep .el-card__body {
      padding: 0;
    }

    ::v-deep .el-card__header {
      border-bottom: 0;
    }
  }

  ::v-deep .el-card__header {
    position: relative;
    padding: 0;
    height: 60px;

    &:before {
      position: absolute;
      content: '';
      width: 3px;
      height: 24px;
      background-color: $color-primary;
      left: 0;
      top: 18px;
    }
  }
}
</style>
