import Layout from '@/layout/Index.vue'

export default [{
  path: '/power',
  meta: { title: '停发电信息' },
  component: Layout,
  redirect: '/power/main',
  children: [
    {
      path: 'main',
      meta: { title: '停发电列表' },
      component: () => import('@/views/power/PowerMain')
    },
    {
      path: 'setting',
      meta: { title: '告警消息配置' },
      component: () => import('@/views/power/AlarmSetting')
    }
  ]
}]
